import React, { RefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'
import { AuBNBImages } from 'src/static/images/landing/aubnb'

import './AuBNBFeatures.sass'
import MoreAuBNBButton from 'src/components/MoreAuBNBButton/MoreAuBNBButton'

export enum AuBNBFeaturesEnum {
  AUTOCOMPOUNDING = 'autocompounding',
  COLLATERAL = 'collateral',
  ABSTRACTION = 'abstraction',
  WITHDRAW = 'withdraw'
}

const AuBNBFeatures = ({
  scrollable
}: {
  scrollable?: RefObject<HTMLElement>
}) => {
  const section = useScrollOver('#landing-aubnb', scrollable)

  return (
    <section
      id="landing-aubnb"
      className={'landing-section ' + (section > 0.15 ? 'enter' : '')}
    >
      <div className="container">
        <h1>
          <MarkdownMessage id="landing-aubnb.title" />
        </h1>
        <div className={'grid-aubnb ' + (section > 0.25 ? 'enter' : '')}>
          {Object.values(AuBNBFeaturesEnum).map((feature, i) => (
            <article
              key={feature + i}
              style={{ transitionDelay: i * 0.25 + 's' }}
            >
              <section className="info">
                <h2>
                  <FormattedMessage id={`landing-aubnb.${feature}-title`} />
                </h2>
                <p>
                  <FormattedMessage id={`landing-aubnb.${feature}-text`} />
                </p>
              </section>
              <img src={AuBNBImages[feature]} alt={`${feature} illustration`} />
            </article>
          ))}
        </div>
        <MoreAuBNBButton withIcon />
      </div>
    </section>
  )
}

export default AuBNBFeatures
