import logoColors from './autolayerIconColors.png'
import logoBlue from './autolayerIconBlue.png'
import autolayerIconDark from './autolayerIconDark.svg'
import autolayerIconWhite from './autolayerIconWhite.svg'
import autolayerLogoDark from './autolayerLogoDark.svg'
import autolayerLogoWhite from './autolayerLogoWhite.svg'
import autolayerPoints from './autolayerIconColors.svg'
import autolayerIconColor from './autolayerIconColor.svg'
import autolayerLogoColor from './autolayerLogoColor.svg'
import token from './token.png'

export const AutolayerLogos = {
  logoColors,
  logoBlue,
  autolayerPoints,
  autolayerLogoWhite,
  autolayerLogoDark,
  autolayerIconWhite,
  autolayerIconDark,
  autolayerIconColor,
  autolayerLogoColor,
  token
}
