import React, { useState } from 'react'

import LanguageSelectorDialog from 'src/components/LanguageSelectorDialog/LanguageSelectorDialog'
// import language from 'src/static/images/network-selector/global.svg'
import { useSelector } from 'react-redux'

import './LanguageSelectorHeader.sass'

const LanguageSelector = () => {
  // const dispatch = useDispatch()
  const [chooseLanguage, setChooseLanguage] = useState<boolean>(false)
  const locale = useSelector((s: { intl: { locale: string } }) => s.intl.locale)

  return (
    <>
      <section className="language-selector-header">
        <div onClick={() => setChooseLanguage(true)}>
          {/* <img loading="lazy" src={language} alt="globe symbol" /> */}
          <span>{locale.toUpperCase()}</span>
          <span className="icon icon-angle_down" />
        </div>
      </section>
      <LanguageSelectorDialog
        chooseLanguage={chooseLanguage}
        setChooseLanguage={setChooseLanguage}
      />
    </>
  )
}

export default LanguageSelector
