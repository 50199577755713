import React, { RefObject } from 'react'
import { useScrollOver } from '@gluedigital/scrollastic'

import './Parallax.sass'
import landL from 'src/static/images/landing/new-hero-banner/land-right.webp'
import landR from 'src/static/images/landing/new-hero-banner/land-left.webp'

import landLDefault from 'src/static/images/landing/new-hero-banner/land-right.png'
import landRDefault from 'src/static/images/landing/new-hero-banner/land-left.png'

interface ParallaxProps {
  scrollable: RefObject<HTMLDivElement>
}

const Parallax = ({ scrollable }: ParallaxProps) => {
  const parallax = useScrollOver('#layer1', scrollable)
  const position = (multiplier, c = -45) => c + parallax * multiplier + '%'
  return (
    <div id="parallax">
      <div
        style={{ bottom: position(47) }}
        className="layer layer-bottom"
        id="layer1"
      >
        <picture>
          <source srcSet={landL} type="image/webp" />
          <img src={landLDefault} alt="Land" />
        </picture>
      </div>
      <div
        style={{ bottom: position(27) }}
        className="layer layer-bottom"
        id="layer2"
      >
        <picture>
          <source srcSet={landR} type="image/webp" />
          <img src={landRDefault} alt="Land" />
        </picture>
      </div>
    </div>
  )
}

export default Parallax
