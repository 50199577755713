import React from 'react'
import { FormattedMessage } from 'react-intl'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { AutolayerLogos } from 'src/static/logos/autolayer'

import './GoToAirdropChecker.sass'

const GoToAidropChecker = () => {
  return (
    <ErrorBoundary>
      <div id="go-to-leaderboard">
        <div className="wrapper">
          <img src={AutolayerLogos.token} alt="Lay3r Logo" />
          <p>
            <FormattedMessage
              id="go-to-airdrop-checker.text"
              defaultMessage="Check your LAY3R allocation now by clicking {here}."
              values={{
                here: (
                  <a
                    href="https://claim.autolayer.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage
                      id="go-to-airdrop-checker.link"
                      defaultMessage="here"
                    />
                  </a>
                )
              }}
            />
          </p>
          <img src={AutolayerLogos.token} alt="Lay3r Logo" />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default GoToAidropChecker
