import React from 'react'
import { FormattedMessage } from 'react-intl'

import aubnb from 'src/static/images/network-selector/aubnb.svg'
import './MoreAuBNBButton.sass'

const MoreAuBNBButton = ({ withIcon = false }: { withIcon?: boolean }) => {
  return (
    <a
      className="more-auBNB-button"
      href="https://app.autolayer.io/info/aubnb"
      target="_blank"
      rel="noopener noreferrer"
    >
      {withIcon && <img src={aubnb} alt="auBNB" />}
      <span>
        <FormattedMessage id="discover-AuBNB" />
      </span>
    </a>
  )
}

export default MoreAuBNBButton
