import React, { RefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import MarkdownMessage from '@gluedigital/markdown-message'
import LaunchFast from 'src/components/LaunchFast/LaunchFast'
import MoreAuBNBButton from 'src/components/MoreAuBNBButton/MoreAuBNBButton'
import Parallax from './Parallax/Parallax'
import AuBnbApy from './APYs/AuBnbApy'

import aubnb from 'src/static/images/network-selector/aubnb.svg'
import sphere from 'src/static/images/landing/new-hero-banner/sphere.webp'
import sphereDefault from 'src/static/images/landing/new-hero-banner/sphere.png'
import './NewHeroBanner.sass'

interface NewHeroBannerProps {
  scrollable: RefObject<HTMLDivElement>
}

const NewHeroBanner = ({ scrollable }: NewHeroBannerProps) => {
  const section = useScrollOver('#AuBNB-section', scrollable)
  const kernelLink = (
    <a target="_blank" rel="noopener noreferrer" href="https://kerneldao.com/">
      Kernel
    </a>
  )
  return (
    <section id="landing-hero-banner" className="landing-section">
      <div id="background-banner">
        <div className="container">
          <div className="content content-left">
            <div className="wrapper">
              <h1>
                <FormattedMessage id="landing.hero-banner.title" />
              </h1>
              <p>
                <MarkdownMessage id="landing.hero-banner.text" />
              </p>
              <div className="buttons">
                <LaunchFast />
                <MoreAuBNBButton withIcon />
              </div>
            </div>
          </div>
          <div className="content" />
        </div>
        <Parallax scrollable={scrollable} />
      </div>
      <div id="AuBNB-section">
        <div className="container">
          <div className="content content-left">
            <div
              className={
                'wrapper ' + (section > 0.2 && section < 0.9 ? 'enter' : '')
              }
            >
              <h1>
                <strong>
                  <img src={aubnb} alt="BNB" />
                  AuBNB:
                </strong>
                <span>
                  <FormattedMessage id="landing.hero-banner.AuBNB-title" />
                </span>
              </h1>
              <p className="mono-space">
                <FormattedMessage
                  id="landing.hero-banner.AuBNB-text"
                  values={{ kernelLink }}
                />
              </p>
              <a
                className="discover-auBNB-button"
                href="https://app.autolayer.io/info/aubnb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <FormattedMessage id="discover-AuBNB" />
                </span>
                <img src={aubnb} alt="auBNB" />
              </a>
              <div className="apy-boxes">
                <AuBnbApy value={52.1} title="APR" />
                <AuBnbApy value={0.1} title="BNB Staking Rewards" />
                <AuBnbApy value={-1} title="Restaking Rewards" />
                <AuBnbApy value={52} title="AutoLayer Rewards" />
              </div>
            </div>
          </div>
          <div className="content" />
        </div>
      </div>
      <div
        id="sphere-sticky"
        className={section >= 0 && section < 0.9 ? 'enter' : ''}
      >
        <div className="container">
          <div className="content content-left" />
          <div className="content content-right">
            <picture id="sphere" className={section >= 0.2 ? 'glow' : ''}>
              <source srcSet={sphere} type="image/webp" />
              <img src={sphereDefault} alt="Autolayer sphere" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewHeroBanner
