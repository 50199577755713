import abstraction from './abstraction.png'
import autocompounding from './autocompounding.png'
import collateral from './collateral.png'
import withdraw from './withdraw.png'

export const AuBNBImages = {
  abstraction,
  autocompounding,
  collateral,
  withdraw
}
