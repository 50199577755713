import React, { useState } from 'react'
import LaunchFast from '../../LaunchFast/LaunchFast'
import Navigation from '../Navigation'

import icon from 'src/static/images/logos/autolayer-icon-dark.svg'

import './Menu.sass'
import MoreAuBNBButton from 'src/components/MoreAuBNBButton/MoreAuBNBButton'

const Menu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <div id="landing-menu" className={isOpen ? 'menu-open' : 'menu-close'}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <span className={`icon icon-${isOpen ? 'close' : 'burger'}`} />
      </button>
      <div className="nav-container">
        <img
          loading="lazy"
          className="symbol-image"
          src={icon}
          alt="autolayer icon"
        />
        <Navigation />
        <LaunchFast />
        <MoreAuBNBButton />
      </div>
    </div>
  )
}

export default Menu
