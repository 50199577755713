import React, { useRef } from 'react'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import Footer from 'src/components/Footer/Footer'
import Header from 'src/components/Header/Header'
import Partners from './Partners/Partners'
import Features from './Features/Features'
import Explore from './Explore/Explore'
import Community from './Community/Community'
import TokenSection from './TokenSection/TokenSection'
import About from './About/About'
import NewHeroBanner from './NewHeroBanner/NewHeroBanner'
import AuBNBFeatures from './AuBNBFeatures/AuBNBFeatures'

import './Landing.sass'

const Landing = () => {
  const scrollable = useRef<HTMLDivElement>(null)

  return (
    <div id="landing" className="page external-page" ref={scrollable}>
      <Header scrollable={scrollable} />
      <NewHeroBanner scrollable={scrollable} />
      <AuBNBFeatures scrollable={scrollable} />
      <ErrorBoundary fallback={<div />}>
        <Explore scrollable={scrollable} />
      </ErrorBoundary>
      <Features scrollable={scrollable} />
      <TokenSection scrollable={scrollable} />
      <Community scrollable={scrollable} />
      <Partners scrollable={scrollable} />
      <About scrollable={scrollable} />
      <Footer />
    </div>
  )
}

export default Landing
